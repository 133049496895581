import { css } from 'styled-components';

export const BoxShadow = ({shadowStyle}) => css`
  -webkit-box-shadow: ${shadowStyle};
          box-shadow: ${shadowStyle};
`;

export const ClipPath = ({clipPath}) => css`
  -webkit-clip-path: ${clipPath};
          clip-path: ${clipPath};
`;

export const PositionAbsolute = ({top, right, bottom, left}) => css`
  bottom: ${bottom || '0'};
  left: ${left || '0'};
  margin: auto;
  right: ${right || '0'};
  position: absolute;
  top: ${top || '0'};
`;

export const SectionWidth = ({maxWidth}) => css`
  max-width: ${maxWidth || '1024px'};
  width: 88%;
`;

export const Transform = ({transformStyle}) => css`
  -webkit-transform: ${transformStyle};
     -moz-transform: ${transformStyle};
      -ms-transform: ${transformStyle};
       -o-transform: ${transformStyle};
          transform: ${transformStyle};
`;