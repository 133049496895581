import FooterCrown from './FooterCrown';
import FooterThrone from './FooterThrone';
import styled from 'styled-components';

const Container = styled.footer`
  padding-top: 128px;
  width: 100%;

  @media screen and (min-width: 1024px) {
    margin-top: 64px;
  }
`;

const Footer = (props) => {

  return (
    <Container>
      <FooterCrown />
      <FooterThrone />
    </Container>
  );
}

export default Footer;