import Button from '../button';
import styled from 'styled-components';
import { SectionWidth } from '../../style/Mixins';

const Crown = styled.section`
  align-items: center;
  -webkit-display: flex;
  display: flex;
  justify-content: center;
  overflow: visible;
  position: relative;
  width: 100%;
  z-index: 0;

  &::after {
    background: var(--colorCoral900);
    bottom: 0;
    content: "";
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    transform: skewY(8deg) scale3d(1, 4, 1) translate3d(0%, 24%, 1px);
    z-index: -1;
  }

  & > div {
    -webkit-display: flex;
    display: flex;
    flex-direction: column;
    ${SectionWidth};
  }

  & > div > h3 > span {
    font-weight: 200;
  }

  & button {
    margin-top: 8px;
  }
`;

const FooterCrown = () => {
  return (
    <Crown>
      <div>
        <h3>
          Ready to get growing?
          <br/>
          <span>Let's have a chat.</span>
        </h3>
        <Button label="Get in contact" color="coral" format="filled" onClick={ () => window.location="/about" } />
      </div>
    </Crown>
  );
}

export default FooterCrown;