import ButtonArrow from './ButtonArrow';
import styled from 'styled-components';

const Container = styled.button`
  --buttonSidePadding: 20px;
  --buttonTransition: transform .2s ease-in-out;
  align-items: center;
  align-self: flex-start;
  background: ${props => props.buttonBackground};
  box-shadow: ${props => props.buttonBorder};
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  overflow: hidden;
  padding: 8px 16px;
  position: relative;

  &:hover {
    background: ${props => props.buttonBackgroundHover};
    box-shadow: ${props => props.buttonBorderHover};
  }

  & span {
    color: ${props => props.buttonTypeColor};
    font-weight: 500;
    position: relative;
    transform: translate3d(0px, 0px, 1px);
    transition: var(--buttonTransition);
    z-index: 1;
  }

  & .button__arrow,
  & .button__arrow::after,
  & .button__arrow::before {
    background: ${props => props.buttonTypeColor};
  }

  @media screen and (min-width: 1024px) {
    --buttonSidePadding: 24px;
    font-size: 18px;
    padding: 12px var(--buttonSidePadding);

    &:hover span {
      transform: translate3d(-10px, 0px, 1px);
    }
  
    &:hover .button__arrow {
      opacity: 1;
      transform: translate3d(10px, 0, 1px);
    }

    &:hover::after,
    &:hover::before {
      background: ${props => props.buttonTypeColor};
    }
  }
`;

const Button = (props) => {
  let buttonBorderColor, buttonBorderHoverColor, buttonColor, buttonHoverColor;
  let buttonBackground, buttonBackgroundHover, buttonBorder, buttonBorderHover, buttonTypeColor;

  switch(props.color) {
    case 'coral':
      buttonColor = 'var(--colorCoral700)';
      buttonHoverColor = 'var(--colorCoral750)';
      buttonBorderColor = 'rgba(0, 0, 0, .48)';
      buttonBorderHoverColor = 'rgba(0, 0, 0, .64)';
      break;
    case 'ink':
      buttonColor = 'var(--colorInk100)';
      buttonHoverColor = '';
      buttonBorderColor = 'rgba(0, 0, 0, .48)';
      buttonBorderHoverColor = 'rgba(0, 0, 0, .64)';
      break;
    case 'paper':
      buttonColor = 'var(--primaryPaper)';
      buttonHoverColor = '';
      buttonBorderColor = 'rgba(255, 255, 255, .48)';
      buttonBorderHoverColor = 'rgba(255, 255, 255, .64)';
      break;
    default:
      buttonColor = 'var(--colorInk100)';
      buttonHoverColor = '';
      buttonBorderColor = 'rgba(0, 0, 0, .48)';
      buttonBorderHoverColor = 'rgba(0, 0, 0, .64)';
  }

  switch(props.format) {
    case 'filled':
      buttonBackground = buttonColor;
      buttonBackgroundHover = buttonHoverColor;
      buttonBorder = 'none';
      buttonBorderHover = 'none';
      buttonTypeColor = 'var(--colorInk100)';
      break;
    case 'outlined':
      buttonBackground = 'transparent';
      buttonBackgroundHover = 'transparent';
      buttonBorder = `inset 0 0 0 2px ${buttonBorderColor}`;
      buttonBorderHover = `inset 0 0 0 2px ${buttonBorderHoverColor}`;
      buttonTypeColor = buttonColor;
      break;
    case 'ghost':
      buttonBackground = 'transparent';
      buttonBackgroundHover = 'transparent';
      buttonBorder = 'none';
      buttonBorderHover = 'none';
      buttonTypeColor = buttonColor;
      break;
    default:
      buttonBackground = 'transparent';
      buttonBackgroundHover = 'transparent';
      buttonBorder = `inset 0 0 0 2px ${buttonHoverColor}`;
      buttonBorderHover = `inset 0 0 0 2px ${buttonBorderHoverColor}`;
      buttonTypeColor = 'var(--colorInk100)';
  }

  return (
    <Container 
    onClick={ props.onClick } 
    buttonBackground={ buttonBackground } 
    buttonBackgroundHover={ buttonBackgroundHover } 
    buttonBorder={ buttonBorder } 
    buttonBorderHover={ buttonBorderHover } 
    buttonTypeColor={ buttonTypeColor }>
      <span>
        { props.label }
      </span>
      <ButtonArrow />
    </Container>
  );
}

export default Button;