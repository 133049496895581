import styled from 'styled-components';

const timing = '.2s ease-in-out';
const ButtonArrowContainer = styled.div`
  background-color: var(--colorInk100);
  border-radius: 1px;
  height: 2px;
  right: var(--buttonSidePadding);
  opacity: 0;
  margin-top: 2px;
  position: absolute;
  transition: opacity ${timing}, transform ${timing};
  width: 12px;
  z-index: 1;

  &::after,
  &::before {
    background-color: var(--colorInk100);
    bottom: 0;
    content: "";
    height: 2px;
    margin: auto;
    position: absolute;
    right: 1px;
    top: 0;
    transform-origin: right center;
    transition: transform ${timing};
    width: 8px;
  }

  @media screen and (min-width: 1024px) {
    &::after {
      transform: rotate(45deg);
    }

    &::before {
      transform: rotate(-45deg);
    }
  }
`;

const ButtonArrow = () => {
  return (
    <ButtonArrowContainer className="button__arrow" />
  );
}

export default ButtonArrow;