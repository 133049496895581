import { useState } from 'preact/hooks';
import styled from 'styled-components';
import { PositionAbsolute } from '../../style/Mixins';
import HeaderMenuButton from './HeaderMenuButton';

const Navigation = styled.nav`
  --headerButtonWidth: 112px;
  --menuHeight: 320px;
  --menuTiming: .2s ease-in-out;
  --menuWidth: calc(100vw - 32px);
  height: var(--headerButtonSize);
  pointer-events: all;
  position: relative;
  width: var(--headerButtonWidth);
  z-index: 64;

  @media screen and (min-width: 768px) {
    --menuHeight: 400px;
    --menuWidth: 600px;
  }
`;

const NavigationMenuContainer = styled.div`
  ${PositionAbsolute({bottom: 'auto', left: 'auto'})};
  background: var(--primaryInk);
  box-shadow: var(--shadowDefault);
  overflow: hidden;
  transition: border-radius var(--menuTiming), 
              box-shadow var(--menuTiming), 
              height var(--menuTiming), 
              width var(--menuTiming);

  &.navigationMenuClosed {
    border-radius: 8px;
    height: var(--headerButtonSize);
    width: var(--headerButtonWidth);
  }

  &.navigationMenuOpen {
    border-radius: 0;
    height: var(--menuHeight);
    width: var(--menuWidth);
  }
`;

const NavigationMenu = styled.ul`
  color: #ffffff;
  display: flex;
  height: var(--menuHeight);
  flex-direction: column;
  justify-content: center;
  padding: 24px;
  position: absolute;
  right: 0;
  top: 0;
  width: var(--menuWidth);

  & li a {
    display: inline-block;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 2px;
    line-height: 48px;
    padding: 8px;
    position: relative;
  }

  & li a::after {
    background-image: linear-gradient(-45deg, #ff9a9e 0%, #fad0c4 100%);
    bottom: 0;
    content: '';
    height: 2px;
    left: auto;
    margin:  auto;
    position: absolute;
    right: -48px;
    top: 6px;
    transform: scale3d(0, 1, 1);
    transform-origin: top left;
    transition: transform .2s ease-in-out;
    width: 40px;
  }

  & li a:active::after {
    transform: scale3d(1, 1, 1);
  }

  @media screen and (min-width: 768px) {
    --menuHeight: 400px;
    --menuWidth: 600px;
  }

  @media screen and (min-width: 1024px) {
    & li a {
      font-size: 48px;
      line-height: 56px;
    }
    
    & li a::after {
      left: -48px;
      right: -48px;
      width: auto;
    }

    & li a:hover::after {
      transform: scale3d(1, 1, 1);
    }
  }
`;

const ScrimContainer = styled.div`
  ${PositionAbsolute};
  position: fixed;
`;

let lastScrollY = 0;

const HeaderMenu = () => {
  const [menuIsOpen, setMenuState] = useState(false);

  // Locks the body to prevent scrolling, and shifts the page based on scroll height.
  // This prevents the "popping" effect of plugged-in mice when the scrollbar disappears.
  const toggleBodyLock = () => {
    let bodyStyles = {
      height: 'auto',
      marginTop: '0px',
      overflowY: 'auto',
      position: 'relative',
    }
    // If menuIsOpen is false, then set lastScrollY and edit bodyStyles object.
    if (!menuIsOpen) {
      lastScrollY = window.pageYOffset || document.documentElement.scrollTop;
      bodyStyles = {
        height: '100vh',
        marginTop: `${-lastScrollY}px`,
        overflowY: 'scroll',
        position: 'fixed',
      }
    }
    // Set styles based on bodyStyles object.
    for (let bodyStyle in bodyStyles) {
      document.body.style[bodyStyle] = bodyStyles[bodyStyle];
    }
    // Shift the header into place, scroll to the correct position, and reset the state.
    document.getElementById('header').style.marginTop = `${menuIsOpen ? 0 : lastScrollY}px`;
    menuIsOpen && window.scrollTo(0, lastScrollY);
    setMenuState(!menuIsOpen);
  }

  // This is a placeholder until I figure out "click outside".
  const Scrim = () => {
    return (
      <ScrimContainer id='scrim' onClick={ () => toggleBodyLock() }></ScrimContainer>
    );
  }

  return (
    <Navigation id='menu'>
      { menuIsOpen && <Scrim /> }
      <NavigationMenuContainer className={ menuIsOpen ? 'navigationMenuOpen' : 'navigationMenuClosed' }>
        <NavigationMenu>
          <li><a onClick={ () => { window.location='/' } }>Home</a></li>
          <li><a onClick={ () => { window.location='/work' } }>Work</a></li>
          <li><a onClick={ () => { window.location='/about' } }>About</a></li>
          <li><a onClick={ () => { window.location='/about' } }>Contact</a></li>
        </NavigationMenu>
        <HeaderMenuButton menuIsOpen={ menuIsOpen } onClick={ () => toggleBodyLock() } />
      </NavigationMenuContainer>
    </Navigation>
  );
}

export default HeaderMenu;
