import styled, { keyframes } from 'styled-components';
import { PositionAbsolute, Transform } from '../../style/Mixins';

const animationTiming = 400;
const menuIconDistanceFromCenter = 6;
const closeIconRotation = 45;

const Button = styled.button`
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  -webkit-display: flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: var(--headerButtonSize);
  justify-content: space-between;
  right: 0;
  margin: auto;
  padding: 0 12px;
  position: absolute;
  top: 0;
  width: var(--headerButtonWidth);

  & div {
    --spanHeight: 24px;
    flex-basis: 56%;
    height: var(--spanHeight);
    overflow: hidden;
    position: relative;
  }

  & div span {
    color: #fff;
    display: block;
    font-weight: 600;
    left: 0;
    letter-spacing: .5px;
    line-height: var(--spanHeight);
    position: absolute;
    right: 0;
    text-align: center;
    text-transform: uppercase;
    transition: transform ${animationTiming}ms linear;
  }

  &.menuButtonMenuClosed {
    & div span:first-child {
      transform: translate3d(0px, 0%, 1px);
    }

    & div span:last-child {
      transform: translate3d(0px, 100%, 1px);
    }
  }

  &.menuButtonMenuOpen {
    & div span:first-child {
      transform: translate3d(0px, -100%, 1px);
    }

    & div span:last-child {
      transform: translate3d(0px, 0%, 1px);
    }
  }
`;

const MenuToClose = ({closeRotation, verticalPosition}) => keyframes`
  0% {
    transform: rotate(0deg) translate3d(0px, ${verticalPosition}px, 1px);
  },
  40% {
    transform: rotate(0deg) translate3d(0px, 0px, 1px);
  },
  100% {
    transform: rotate(${closeRotation}deg) translate3d(0px, 0px, 1px);
  }
`;

const Icon = styled.ul`
  height: 48px;
  list-style: none;
  position: relative;
  flex-basis: 32%;

  & li {
    ${PositionAbsolute};
    --closeRotation: ${closeIconRotation};
    --menuVerticalPosition: ${menuIconDistanceFromCenter};
    background-color: #ffffff;
    height: 2px;
    width: 80%;
  }

  & li.iconAnimate {
    animation: ${MenuToClose({closeRotation: 'var(--closeRotation)', verticalPosition: 'var(--menuVerticalPosition)'})} ${animationTiming}ms ease-in-out;
  }

  &.iconClose {
    & li:nth-child(1) {
      opacity: 1;
      transform: rotate(${closeIconRotation}deg) translate3d(0px, 0px, 1px);
    }

    & li:nth-child(2) {
      opacity: 0;
    }
  
    & li:nth-child(3) {
      opacity: 1;
      transform: rotate(-${closeIconRotation}deg) translate3d(0px, 0px, 1px);
    }
  }

  &.iconMenu {
    & li:nth-child(1) {
      opacity: 1;
      transform: rotate(0deg) translate3d(0px, -${menuIconDistanceFromCenter}px, 1px);
    }

    & li:nth-child(2) {
      opacity: 1;
    }

    & li:nth-child(3) {
      opacity: 1;
      transform: rotate(0deg) translate3d(0px, ${menuIconDistanceFromCenter}px, 1px);
    }
  }
`;

const HeaderMenuButtonIcon = (props) => {
  return (
    <Icon id='menuIcon' className={ props.class }>
      <li></li>
      <li></li>
      <li></li>
    </Icon>
  );
}

const HeaderMenuButton = (props) => {
  
  // Set default variables when Menu is not open.
  let buttonClass = 'menuButtonMenuClosed';
  let iconClass = 'iconMenu';

  // Update variables when Menu is open.
  if (props.menuIsOpen) {
    buttonClass = 'menuButtonMenuOpen';
    iconClass = 'iconClose';
  }

  // Functions to run when the Menu Button is clicked.
  const handleClick = () => {
    const menuIcon = document.getElementById('menuIcon');
    const menuIconFirstLine = menuIcon.childNodes[0];
    const menuIconLastLine = menuIcon.childNodes[2];

    props.onClick();
    menuIconFirstLine.classList.add('iconAnimate');
    menuIconLastLine.classList.add('iconAnimate');

    // Remove class after animation is complete.
    setTimeout(() => {
      menuIconFirstLine.classList.remove('iconAnimate');
      menuIconLastLine.classList.remove('iconAnimate');
    }, animationTiming);
  }

  return (
    <Button className={ buttonClass } onClick={ handleClick } onChange={ props.onChange }>
      <div>
        <span>Menu</span>
        <span>Close</span>
      </div>
      <HeaderMenuButtonIcon class={ iconClass } menuIsOpen={ props.menuIsOpen } />
    </Button>
  );
}

export default HeaderMenuButton;