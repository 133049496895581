import HeaderAvatar from './HeaderAvatar';
import HeaderMenu from './HeaderMenu';
import styled from 'styled-components';

const HeaderContainer = styled.header`
  --headerPadding: 16px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--headerPadding);
  pointer-events: none;
  position: fixed;
  width: 100%;
  z-index: 64;

  @media screen and (min-width: 1024px) and (orientation: landscape) {
    --headerPadding: 24px;
  }
`;

const Header = () => {
  return (
    <HeaderContainer id="header">
      <HeaderAvatar />
      <HeaderMenu />
    </HeaderContainer>
  );
}

export default Header;