import { Router, Route } from 'preact-router';
import Helmet from 'preact-helmet';
import Header from './header';
import Footer from './footer';
import Splash from './splash';

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import About from '../routes/about';
import CaseStudy from '../routes/study';
import Work from '../routes/work';

const App = () => {

  const caseStudies = require('../assets/data/work.json');

  return (
    <div id="app">
      {/* <Splash /> */}
      <Helmet defaultTitle="Anthony Foster / Product &amp; Design Director" />
      <Header />
      <Router>
        <Home path="/" pageTitle="Anthony Foster / Product &amp; Design Director" />
        <About path="/about" pageTitle="All about Anthony Foster" />
        <Work path="/work" pageTitle="Work by Anthony Foster" data={caseStudies} headline="The fruits of labor." copy="Find a case study that strikes you and give me a holler." />
        <CaseStudy path="/work/:studyName" data={caseStudies} />
        <Route path="/:rest*">404</Route>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
