import styled from 'styled-components';

const Avatar = styled.a`
  border-radius: 50%;
  height: var(--headerButtonSize);
  overflow: hidden;
  pointer-events: all;
  position: relative;
  transition: var(--shadowTransition);
  width: var(--headerButtonSize);

  &::after,
  &::before {
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: transform .1s ease-in-out;
    z-index: 0;
  }

  &::after {
    background: hsla(187, 100%, 30%, .4);
    transform: translate3d(0%, 0%, 1px);
  }
  
  &::before {
    background: var(--colorCoral600);
    transform: translate3d(0%, 100%, 1px);
  }

  & svg {
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  @media screen and (min-width: 1024px) and (orientation: landscape) {
    &:hover {
      box-shadow: var(--shadowHoveredSubtle);
    }

    &:hover::before {
      transform: translate3d(0%, 0%, 1px);
    }

    &:hover::after {
      transform: translate3d(0%, -100%, 1px);
    }
  }
`;

const HeaderAvatar = () => {
  return (
    <Avatar onClick={ () => window.location='/' }>
      <svg width="100%" height="100%" viewBox="0 0 800 800" version="1.1" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;">
        <g id="Layer1">
          <path d="M239.216,286.275c0,0 -42.153,-3.037 -52.292,-19.975c0,0 23.644,-22.849 11.975,-28.043c-0.494,-0.245 -0.81,-0.746 -0.818,-1.297c-0.008,-0.552 0.293,-1.061 0.779,-1.321c9.455,-5.17 35.423,-20.787 32.083,-35.361c-4.074,-17.776 -18.57,-11.118 -18.654,-17.571c-0.085,-6.454 22.89,-1.831 60.209,-25.189c37.319,-23.357 113.596,-59.252 199.815,-39.995c86.218,19.257 92.351,62.59 101.548,61.775c9.198,-0.815 4.748,-16.78 3.417,-22.32c1.594,-1.998 13.708,2.745 16.305,11.718c2.201,7.602 -4.138,15.614 -13.325,16.319c-0.353,0.025 -0.651,0.275 -0.736,0.618c-0.085,0.344 0.061,0.704 0.362,0.891c12.479,8.487 70.71,52.771 71.09,141.148c0.669,155.637 -135.774,188.82 -115.884,221.327c0.099,0.148 0.105,0.339 0.016,0.493c-0.089,0.154 -0.259,0.244 -0.436,0.232c-5.337,-0.643 -31.855,-5.546 -49.576,-40.203c45.748,89.472 -118.76,-84.707 -245.878,-223.246Z"/>
          <path d="M203.163,348.977c-0.832,0.173 -1.555,0.682 -1.998,1.407c-0.443,0.725 -0.567,1.6 -0.341,2.419c3.386,12.293 15.492,56.249 15.492,56.249l7.271,-2.695c0,0 -11.349,-43.941 -14.373,-55.647c-0.41,-1.589 -1.995,-2.575 -3.601,-2.242c-0.787,0.164 -1.645,0.342 -2.45,0.509Z" style="fill:#d9d9d9;"/>
          <path d="M239.679,249.629c0,0 -20.91,42.486 -16.664,71.395c4.246,28.908 3.245,52.289 -19.63,74.919c-18.235,18.04 -31.313,31.735 -35.867,36.549c-1.18,1.256 -1.789,2.944 -1.683,4.664c0.58,6.971 3.945,24.878 23.661,28.703c24.612,4.776 24.166,14.013 20.672,21.157c-2.38,4.867 -4.821,9.998 -6.135,12.775c-0.659,1.393 -0.576,3.023 0.219,4.342c2.109,3.422 7.169,9.884 16.84,13.148c0,0 -8.526,10.348 -8.119,22.464c0.08,1.693 1.09,3.203 2.625,3.922c5.811,2.812 20.464,10.656 20.082,19.004c-0.478,10.413 -10.139,37.878 6.763,49.747c16.902,11.869 43.791,8.67 73.622,-2.594c29.831,-11.265 82.638,23.154 106.825,49.03c24.187,25.877 24.616,37.145 24.616,37.145l125.692,-100.523c0,0 -35.434,-26.319 -61.315,-51.421c-14.6,-14.161 -25.393,-31.103 -26.957,-34.918c0,0 1.368,10.894 6.185,17.737c0.239,0.242 0.262,0.624 0.054,0.893c-0.207,0.269 -0.582,0.343 -0.877,0.173c-11.522,-7.686 -38.091,-30.444 -34.675,-74.847c0,0 76.43,0.643 85.914,-51.065c9.429,-51.413 -66.661,-66.555 -78.115,1.267c-0.118,0.698 -0.679,1.236 -1.382,1.325c-4.956,0.633 -24.072,3.063 -32.077,4.08c-0.681,0.087 -1.352,-0.219 -1.734,-0.789c-0.382,-0.571 -0.41,-1.308 -0.071,-1.905c5.071,-9.499 18.679,-39.017 9.463,-66.623c-11.011,-32.98 -48.532,-36.611 -32.285,-74.161c0.164,-0.385 0.123,-0.827 -0.108,-1.176c-0.231,-0.348 -0.622,-0.558 -1.04,-0.558c-2.565,-0.094 -7.388,-0.607 -12.575,-2.851c-7.647,-3.306 -18.827,-18.891 -42.601,-28.085c-23.774,-9.193 -68.473,17.859 -109.323,17.077" style="fill:#fff;"/>
          <clipPath id="_clip2">
            <path d="M239.679,249.629c0,0 -20.91,42.486 -16.664,71.395c4.246,28.908 3.245,52.289 -19.63,74.919c-18.235,18.04 -31.313,31.735 -35.867,36.549c-1.18,1.256 -1.789,2.944 -1.683,4.664c0.58,6.971 3.945,24.878 23.661,28.703c24.612,4.776 24.166,14.013 20.672,21.157c-2.38,4.867 -4.821,9.998 -6.135,12.775c-0.659,1.393 -0.576,3.023 0.219,4.342c2.109,3.422 7.169,9.884 16.84,13.148c0,0 -8.526,10.348 -8.119,22.464c0.08,1.693 1.09,3.203 2.625,3.922c5.811,2.812 20.464,10.656 20.082,19.004c-0.478,10.413 -10.139,37.878 6.763,49.747c16.902,11.869 43.791,8.67 73.622,-2.594c29.831,-11.265 82.638,23.154 106.825,49.03c24.187,25.877 24.616,37.145 24.616,37.145l125.692,-100.523c0,0 -35.434,-26.319 -61.315,-51.421c-14.6,-14.161 -25.393,-31.103 -26.957,-34.918c0,0 1.368,10.894 6.185,17.737c0.239,0.242 0.262,0.624 0.054,0.893c-0.207,0.269 -0.582,0.343 -0.877,0.173c-11.522,-7.686 -38.091,-30.444 -34.675,-74.847c0,0 76.43,0.643 85.914,-51.065c9.429,-51.413 -66.661,-66.555 -78.115,1.267c-0.118,0.698 -0.679,1.236 -1.382,1.325c-4.956,0.633 -24.072,3.063 -32.077,4.08c-0.681,0.087 -1.352,-0.219 -1.734,-0.789c-0.382,-0.571 -0.41,-1.308 -0.071,-1.905c5.071,-9.499 18.679,-39.017 9.463,-66.623c-11.011,-32.98 -48.532,-36.611 -32.285,-74.161c0.164,-0.385 0.123,-0.827 -0.108,-1.176c-0.231,-0.348 -0.622,-0.558 -1.04,-0.558c-2.565,-0.094 -7.388,-0.607 -12.575,-2.851c-7.647,-3.306 -18.827,-18.891 -42.601,-28.085c-23.774,-9.193 -68.473,17.859 -109.323,17.077"/>
          </clipPath>
          <g clip-path="url(#_clip2)">
            <path d="M454.994,453.073c0,0 -0.987,36.648 -26.204,56.417l60.548,-22.447l-31.942,-33.957l-2.402,-0.013Z" style="fill:#ffd600;"/>
            <path d="M221.458,546.79c0,0 17.309,1.337 23.158,5.866c7.517,5.82 5.593,17.859 -2.954,14.037c-5.812,-2.599 -8.159,-8.64 -12.718,-10.592c-6.379,-2.732 -7.486,-9.311 -7.486,-9.311Z" style="fill:#ffd600;"/>
          </g>
          <path d="M247.128,396.72l-19.626,22.03c0,0 25.747,-15.181 69.628,-13.527c2.309,0.089 4.482,-1.09 5.667,-3.074c1.184,-1.984 1.19,-4.457 0.015,-6.447c-4.607,-7.836 -10.158,-17.24 -10.158,-17.24l-43.444,4.841l-2.082,13.417Z" style="fill:#ffd600;"/>
          <path d="M208.737,363.862c0,0 10.261,-11.799 27.66,-0.304l2.039,7.133c0,0 -18.25,-9.828 -26.666,-0.644l-3.033,-6.185" style="fill:#d9d9d9;"/>
          <path d="M232.438,345.183c-1.218,0.037 -2.357,0.616 -3.105,1.578c-0.749,0.962 -1.031,2.207 -0.769,3.398c2.939,13.342 11.213,50.903 15.053,68.336c1.185,5.379 5.808,9.31 11.307,9.616c2.86,0.16 6.085,0.339 9.229,0.514c3.691,0.206 7.279,-1.264 9.766,-3.999c2.487,-2.736 3.609,-6.447 3.053,-10.102c-3.089,-20.343 -8.432,-55.516 -10.145,-66.796c-0.312,-2.049 -2.105,-3.543 -4.177,-3.479c-6.987,0.216 -22.306,0.689 -30.212,0.934Z" style="fill:#d9d9d9;"/>
          <clipPath id="_clip3">
            <path d="M232.438,345.183c-1.218,0.037 -2.357,0.616 -3.105,1.578c-0.749,0.962 -1.031,2.207 -0.769,3.398c2.939,13.342 11.213,50.903 15.053,68.336c1.185,5.379 5.808,9.31 11.307,9.616c2.86,0.16 6.085,0.339 9.229,0.514c3.691,0.206 7.279,-1.264 9.766,-3.999c2.487,-2.736 3.609,-6.447 3.053,-10.102c-3.089,-20.343 -8.432,-55.516 -10.145,-66.796c-0.312,-2.049 -2.105,-3.543 -4.177,-3.479c-6.987,0.216 -22.306,0.689 -30.212,0.934Z"/>
          </clipPath>
          <g clip-path="url(#_clip3)">
            <path d="M254.939,350.949l-20.998,34.896l2.12,10.79l22.766,-31.819l-3.888,-13.867Z" style="fill:#e6e6e6;"/>
            <path d="M260.789,370.674l-25.612,31.666l0.887,4.965l27.36,-30.727l-2.635,-5.904Z" style="fill:#e6e6e6;"/>
          </g>
          <path d="M252.169,344.573l9.752,-0.302c2.488,-0.076 4.641,1.717 5.015,4.178c1.733,11.408 6.514,42.885 9.581,63.077c0.667,4.389 -0.68,8.844 -3.666,12.129c-1.518,1.67 -3.379,2.947 -5.423,3.766l-15.259,-82.848Z" style="fill:#f2f2f2;"/>
          <path d="M221.036,517.262c0,0 13.923,3.83 26.004,4.203c0,0 -19.159,2.924 -29.15,0.627c0,0 1.284,-3.208 3.149,-4.829" style="fill:#e6e6e6;"/>
          <clipPath id="_clip4">
            <path d="M418.95,800c0,0 -24.846,-104.548 89.572,-192.55c103.561,-79.652 209.159,-36.517 209.159,-36.517l-164.538,91.55l-134.193,137.517Z"/>
          </clipPath>
          <g clip-path="url(#_clip4)">
            <use x="418.044" y="559.904" width="301.009px" height="240.728px" transform="matrix(0.996719,0,0,0.998871,1.13687e-13,0)"/>
          </g>
          <path d="M320.487,364.609l-55.667,0l0,22.192l39.578,0l15.659,-8.339l0.43,-13.853Z"/>
          <rect x="304.476" y="364.609" width="161.166" height="13.661"/>
          <path d="M257.638,344.404l4.294,-0.133c2.482,-0.077 4.63,1.713 5.002,4.168c1.733,11.407 6.522,42.933 9.59,63.13c0.665,4.378 -0.679,8.823 -3.658,12.1c-0.367,0.404 -0.755,0.785 -1.16,1.142l-14.068,-80.407Z"/>
          <path d="M201.029,462.077c0,0 10.977,-5.262 18.091,-1.301c7.115,3.962 -1.442,3.539 -3.402,3.58c0,0 -3.086,-3.989 -14.689,-2.279Z" style="fill:#d9d9d9;"/>
          <path d="M235.03,450.806c-0.278,-0.309 -0.731,-0.391 -1.099,-0.197c-0.369,0.193 -0.559,0.612 -0.463,1.017c0.834,3.557 1.502,9.098 -1.409,12.203c-0.228,0.229 -0.289,0.575 -0.156,0.869c0.133,0.294 0.434,0.475 0.757,0.455c4.819,-0.261 14.009,-2.285 2.37,-14.347Z" style="fill:#f2f2f2;"/>
        </g>
      </svg>
    </Avatar>
  );
}

export default HeaderAvatar;