import { useCallback } from 'preact';
import { useState } from 'preact/hooks';
import styled from 'styled-components';

const SplashStyle = styled.div`
  align-items: center;
  background: var(--colorCoral750);
  display: flex;
  height: 100vh;
  justify-content: center;
  position: fixed;
  top: 0;
  transform: translate3d(0%, 0%, 1);
  transition: transform .2s ease-out;
  width: 100%;
  z-index: 800;

  & .splash--unload {
    transform: translate3d(0%, 100%, 1);
  }
`;

const Splash = () => {
  const [loading, setLoading] = useState();
  const setLoad = () => {
    setTimeout(
       'splash--unload'
      ), 200
  }
  // const setLoad = useCallback(() => {
  //   setTimeout(
  //     setLoading(loading ? '' : 'splash--unload'), 2000
  //   )
  // }, [loading]);

  return (
    <SplashStyle className={setLoad}></SplashStyle>
  );
}

export default Splash;