import styled from 'styled-components';
import { SectionWidth } from '../../style/Mixins';

const Throne = styled.section`
  --clipPath: polygon(0% 12%, 100% 0%, 100% 100%, 0% 100%);
  align-items: center;
  background: var(--colorInk100);
  -webkit-clip-path: var(--clipPath);
  clip-path: var(--clipPath);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 0;

  @media screen and (min-width: 1200px) {
    --clipPath: polygon(0% 24%, 100% 0%, 100% 100%, 0% 100%);
  }

  & > div {
    ${SectionWidth}
    color: #ffffff;
    display: flex;
    flex-direction: column;
    padding: 128px 0 64px;
    position: relative;
    z-index: 1;

    @media screen and (min-width: 1024px) {
      flex-direction: row;
      justify-content: space-between;
    }

    @media screen and (min-width: 1200px) {
      padding: 256px 0 128px;
    }
  }
`;

const ContainerColumn = styled.div`
  margin-bottom: 16px;
  z-index: 1;

  &:first-child {
    margin-top: 32px;
    
    & li {
      line-height: 24px;
      opacity: .72;
      padding: 4px 0;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  & li h5 {
    display: inline-block;
    font-size: 16px;
    line-height: 24px;
    padding: 0 8px;
  }

  & li a {
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    line-height: 24px;
    padding: 4px 8px;
  }

  & li a:hover {
    background-color: rgba(255, 255, 255, .16);
  }
`;

const FooterThrone = () => {

  const getCaseStudiesLinks = () => {
    const caseStudiesArray = require('../../assets/data/work.json');
    return caseStudiesArray.map(caseStudy => {
      return (
        <li>
          <a onClick={ () => window.location=`/work/${caseStudy.url}` }>{caseStudy.title}</a>
        </li>
      );
    });
  }

  const getPersonalInfo = () => {
    const personalArray = [
      'New York City, NY',
      'English (American)',
      'Copyright Ⓒ Anthony Foster'
    ];

    return personalArray.map(info => {
      return (
        <li>{ info }</li>
      );
    });
  }

  return (
    <Throne>
      <div>
        <ContainerColumn>
          <ul>
            { getPersonalInfo() }
          </ul>
        </ContainerColumn>
        <ContainerColumn>
          <ul>
            <li><h5>Links</h5></li>
            <li><a onClick={ () => window.location="/" }>Home</a></li>
            <li><a onClick={ () => window.location="/about" }>About</a></li>
            <li><a onClick={ () => window.location="/work" }>Work</a></li>
            <li><a onClick={ () => window.location="/about" }>Contact</a></li>
          </ul>
        </ContainerColumn>
        <ContainerColumn>
          <ul>
            <li><h5>Case studies</h5></li>
            { getCaseStudiesLinks() }
          </ul>
        </ContainerColumn>
      </div>
    </Throne>
  );
}

export default FooterThrone;